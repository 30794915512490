export default function(type) {

  let log = (msg) => {
    type.innerHTML += `>> ${msg} <br>`;
  };

  return msg => {

    if(typeof type === "string")
      log = console[type].bind(console);

    log(msg);
  };

}
