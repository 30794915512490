/* global THREE */

import WebVRManager from "./webvr-manager";
import Logger from "./logger";
import VideoManager from "./video-manager";

let log = Logger("info");

export default class App {

  constructor(blobs) {
    log("App initialized with data");

    this.blobs = blobs;
    this.data = this.transformData();

    this.setupApp();
    this.addPanoSphere();
    this.initEventListener();
  }

  setupApp() {
    let renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    document.body.appendChild(renderer.domElement);
    this.renderer = renderer;

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
    this.controls = new THREE.VRControls(this.camera);

    let effect = new THREE.VREffect(this.renderer);
    effect.setSize(window.innerWidth, window.innerHeight);
    this.effect = effect;

    let params = {
      hideButton: false,
      isUndistorted: false
    };

    this.manager = new WebVRManager(this.renderer, this.effect, params);
    this.videoManager = new VideoManager();
  }

  // map data from raw loader output to usable context
  transformData() {

    // Video Textures
    let videos = {};
    if(this.blobs.video) {
      let videoBlobs = this.blobs.video;
      let videoKeys = Object.keys(videoBlobs);

      videoKeys.forEach(videoKey => {
        let videoBlob = videoBlobs[videoKey];

        let video$ = document.createElement("video");
        video$.src = URL.createObjectURL(videoBlob);
        video$.controls = true;
        video$.loop = false;
        //video$.autoplay = true;

        videos[videoKey] = video$;
      });
    }
    // Image Textures
    let images = {};

    if(this.blobs.image) {
      let imageBlobs = this.blobs.image;
      let imageKeys = Object.keys(imageBlobs);

      imageKeys.forEach(imageKey => {
        let imageBlob = imageBlobs[imageKey];
        let image$ = document.createElement("img");
        image$.src = URL.createObjectURL(imageBlob);

        let texture = new THREE.Texture();
        image$.addEventListener("load", () => this.onImageLoad(image$, texture));

        images[imageKey] = texture;
      });
    }

    return {
      images,
      videos
    };

  }

  addPanoSphere() {
    let geometry = new THREE.SphereGeometry(100, 64, 64);

    let video = this.data.videos.bmw_2048_1024;

    video.loop = true;
    this.videoManager.setCurrentVideo(video);
    this.videoManager["$play"].style.visibility = "visible";

    window.vidm = this.videoManager;

    let videoTexture = new THREE.VideoTexture(video);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;
    this._videoTexture = videoTexture;

    let material = new THREE.MeshBasicMaterial();
    material.map = videoTexture;
    material.side = THREE.FrontSide;

    let sphere = new THREE.Mesh(geometry, material);
    sphere.scale.x = -1;

    this.scene.add(sphere);
  }

  update() {
    this.controls.update();
    this.manager.render(this.scene, this.camera);
  }

  initEventListener() {
    window.addEventListener("resize", () => this.onWindowResize());
    this.videoManager.on("video-changed", $video => this.onVideoChanged($video));
  }

  /******************************
   * CALLBACKS / EVENT LISTENER *
   ******************************/
  onImageLoad(image$, texture) {
    texture.image = image$;
    texture.needsUpdate = true;
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  onVideoChanged($video) {
    this._videoTexture.image = $video;
    this.videoManager.replay();
  }

}
