import Emitter from "./emitter";

export default class Loader extends Emitter {

  constructor(responseType) {
    super();

    this.responseType = responseType;
  }

  load({ id, path }) {

    let xhr = new XMLHttpRequest();
    xhr.open("GET", path, true);
    xhr.responseType = this.responseType;

    xhr.addEventListener("progress", event => {
      this.emit("progress", event, id);
    });

    xhr.addEventListener("load", event => {
      let { target } = event;

      if (target.status === 200) {
        var response = target.response;

        this.emit("load", response, id);
      }
    });

    xhr.send();

  }

}
