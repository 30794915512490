import Emitter from "./emitter";
import Loader from "./loader";
import Logger from "./logger";
let log = Logger("info");

export default class BlobManager extends Emitter {
  constructor(data) {
    super();

    this.data = data;
    this.blobLoader = new Loader("blob");

    this.totalLoaded = 0;
    this.totalFiles = 0;

    this.partial = {};

    this.calculatedTotal = 0;
    this.calculatedProgress = 0;

    this.blobs = {};

    this.bindEvents();
  }

  bindEvents() {
    this.blobLoader.on("progress", (event, id) => this.onPartialProgress(event, id));
    this.blobLoader.on("load", (blob, id) => this.onPartialLoad(blob, id));
  }

  start() {
    this.emit("start");
    let keys = Object.keys(this.data);
    this.totalFiles = keys.length;
    keys.forEach(id => {
      this.blobLoader.load({
        id: id,
        path: this.data[id]
      });
    });
  }

  postProcessBlobsObject() {
    let postProcessedBlobs = {};
    Object.keys(this.blobs)
      .forEach(key => {
        let type = this.blobs[key].type.split("/")[0];
        if (type === "")
          log("MIME-Type unknown! [" + key + "]");

        if (!(type in postProcessedBlobs))
          postProcessedBlobs[type] = {};

        postProcessedBlobs[type][key] = this.blobs[key];
      });
    this.emit("load", postProcessedBlobs);
  }

  calcTotalProgress() {
    let partialKeys = Object.keys(this.partial);

    if (this.totalFiles !== partialKeys.length)
      return;

    this.calculatedTotal = 0;
    this.calculatedProgress = 0;

    partialKeys.forEach(key => {
      let [loaded, total] = this.partial[key];

      this.calculatedTotal += total;
      this.calculatedProgress += loaded;
    });

    this.emit("progress", {
      loaded: this.calculatedProgress,
      total: this.calculatedTotal
    });
  }

  /* LISTENER */
  onPartialLoad(blob, id) {
    this.blobs[id] = blob;
    this.totalLoaded++;
    if (this.totalLoaded === this.totalFiles)
      this.postProcessBlobsObject();
  }

  onPartialProgress(event, id) {
    let { loaded, total } = event;
    this.partial[id] = [loaded, total];
    this.calcTotalProgress();
  }
}
