import DataManager from "./data-manager";
import App from "./app";
import Util from "./util";

let dataManager = new DataManager();
var app = null;

let $loader = document.querySelector(".loader");

dataManager.on("data", data => {
  if(Util.isMobile()) {
    document.body.className = "mobile";
  }

  app = new App(data);
  render();
});

dataManager.getData();

function render() {
  app.update();

  requestAnimationFrame(render);
}
