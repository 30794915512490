import Emitter from "./emitter";

export default class VideoManager extends Emitter {

  constructor($currentVideo = null) {
    super();
    this.$currentVideo = $currentVideo;

    if(this.$currentVideo !== null)
      this.bindVideoEvents(this.$currentVideo);

    this._btnCount = 0;
    this._btnOffset = 48;
    this._allButtons = [];

    this.$play = this.createButton("play", true, () => this.onPlayClick());
    this.$sound = this.createButton("sound-on", true, () => this.onSoundClick());
    this.$loop = this.createButton("loop", false, () => this.onLoopClick());
    this.$restart = this.createButton("restart", true, () => this.onRestartClick());
  }

  createButton(className, isActive, cb) {
    let div$ = document.createElement("div");
    div$.className = `icon ${className} ${isActive ? "" : "inactive"}`;
    div$.style.left = `${this._btnCount * this._btnOffset}px`;

    div$.addEventListener("click", () => cb());

    document.body.appendChild(div$);

    this._btnCount++;
    this._allButtons.push(div$);
    return div$;
  }

  bindVideoEvents($video) {
    $video.addEventListener("ended", () => this.onVideoEnded());
    $video.addEventListener("playing", () => this.onVideoPlaying());

    this.$mobilePlay = document.querySelector(".mobile-play");

    if(this.$mobilePlay) {
      this.$mobilePlay.addEventListener("click", () => {
        if (this.$currentVideo.paused) {
          this.$play.className = "icon pause";
          this.$currentVideo.play();
        } else {
          this.$play.className = "icon play";
          this.$currentVideo.pause();
        }

        this.$mobilePlay.style.display = "none";
      });
    }
  }

  setCurrentVideo($video) {
    this.bindVideoEvents($video);
    this.$currentVideo = $video;
    this.emit("video-changed", this.$currentVideo);
  }

  /********
   * UTIL *
   ********/
  resetControls() {
    this.$play.className = "icon play";
    this.$sound.className = "icon sound-on";
    this.$sound.className = "icon loop inactive";
  }
  showAllButtons() {
    if (this.$currentVideo === null)
      console.warn("displaying controls even though current video is not set!");

    this._allButtons
      .forEach(button$ => button$.style.visibility = "visible");
  }
  hideAllButtons() {
    this._allButtons
      .forEach(button$ => button$.style.visibility = "hidden");
  }
  replay() {
    this.onRestartClick();
  }

  /*****************
   * EVENTLISTENER *
   *****************/
  // Buttons
  onPlayClick() {
    if (this.$currentVideo.paused) {
      this.$play.className = "icon pause";
      this.$currentVideo.play();
    } else {
      this.$play.className = "icon play";
      this.$currentVideo.pause();
    }

    if(this.$mobilePlay) {
      this.$mobilePlay.style.display = "none";
    }
  }
  onSoundClick() {
    let muted = this.$currentVideo.muted;
    this.$sound.className = muted ? "icon sound-on" : "icon sound-off";
    this.$currentVideo.muted = !muted;
  }
  onLoopClick() {
    let loop = this.$currentVideo.loop;
    this.$loop.className = loop ? "icon loop inactive" : "icon loop";
    this.$currentVideo.loop = !loop;
  }
  onRestartClick() {
    this.$currentVideo.currentTime = 0;
    this.$currentVideo.play();
  }

  // Video Events
  onVideoEnded() {
    this.$play.className = "icon play";
  }
  onVideoPlaying() {
    this.$play.className = "icon pause";
  }
  onVideoPause() {
    this.$play.className = "icon play";
  }

}
