import Emitter from "./emitter";
import Loader from "./loader";
import BlobManager from "./blob-manager";
import LocalForage from "localforage";

import Logger from "./logger";
let log = Logger("info");

let $loader = document.querySelector(".loader");
let $progressBar = document.querySelector(".progress-bar");

export default class DataManager extends Emitter {

  constructor() {
    super();

    this.blobManager = null;
    this.jsonLoader = new Loader("json");

    this.initListener();
  }

  initListener() {
    this.jsonLoader.on("load", json => this.onJSONLoad(json));
  }

  getData() {
    LocalForage.getItem("vi-blobs", (err, val) => this.onGetItem(err, val));
  }

  /*******************************
   * CALLBACKS / EVENT LISTENERS *
   *******************************/
  onGetItem(err, val) {
    if (err) {
      return log("error while getting data from cache");
    }
    if (val === null) {
      log("no data in cache");
      log("starting blob loading process");
      this.jsonLoader.load({
        id: "json_data",
        path: "./data.json"
      });
    } else {
      log("data served from cache");
      this.hideLoader(() => this.emit("data", val));
    }
  }

  onJSONLoad(json) {
    this.blobManager = new BlobManager(json);
    this.blobManager.on("start", () => this.onBlobStart() );
    this.blobManager.on("progress", data => this.onBlobProgress(data));
    this.blobManager.on("load", blobs => this.onBlobsLoad(blobs));
    this.blobManager.start();
  }

  onBlobStart() {
    this.showLoader();
  }

  onBlobProgress({ loaded = 0, total }) {
    var ratio = (loaded / total * 100).toFixed(2);
    $progressBar.style.width = `${ratio}%`;
  }

  onBlobsLoad(blobs) {
    log("data loaded via XHR");
    log("caching data via IndexedDB / WebSQL");
    LocalForage.setItem("vi-blobs", blobs, (err, val) => this.onSetItem(err, val));
    this.hideLoader(() => this.emit("data", blobs));
  }

  onSetItem(err) {
    if (err) {
      return log("can't cache blobs to IndexedDB");
    }
    log("saved data to IndexedDB");
  }

  showLoader() {
    $loader.className = "loader fadeIn";
    setTimeout(() => {
      $loader.style.display = "block";
    }, 500);
  }

  hideLoader(cb) {
    $loader.className = "loader fadeOut";
    setTimeout(() => {
      cb();
      $loader.style.display = "none";
    }, 500);
  }

}
